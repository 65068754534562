import React from 'react'

 


class Livetv extends React.Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			items : [
			{
      "name": "Star Sports Select 2 HD",
      "tvg": {
        "id": "",
        "name": "",
        "language": "",
        "country": "",
        "logo": "https://vignette.wikia.nocookie.net/logopedia/images/4/46/Sss-hd2-bronze.png/revision/latest/top-crop/width/220/height/220?cb=20170728104956",
        "url": ""
      },
      "group": {
        "title": "DOCUMENTARY"
      },
      "url": "http://87.117.234.107:1935/live/StarSportsSelect2FHD/playlists.m3u8",
      "raw": "#EXTINF:-1 tvg-logo=\"https://www.upcdirectrendeles.eu/upload_files/chanels/aphd.jpg\" group-title=\"DOCUMENTARY\",Animal Planet Hindi\nhttps://streamidvo.multitvsolution.in//live//APHDHindi-a//p4.m3u8"
    },
			 {
      "name": "Star Sports Select 1 HD",
      "tvg": {
        "id": "",
        "name": "",
        "language": "",
        "country": "",
        "logo": "https://www.tvchannelpricelist.com/wp-content/uploads/channels-logo-300/star-sports-select-hd-1-logo-300x300.jpg",
        "url": ""
      },
      "group": {
        "title": "DOCUMENTARY"
      },
      "url": "http://87.117.234.107:1935/live/StarSportsSelect1FHD/playlists.m3u8",
      "raw": "#EXTINF:-1 tvg-logo=\"https://www.upcdirectrendeles.eu/upload_files/chanels/aphd.jpg\" group-title=\"DOCUMENTARY\",Animal Planet Hindi\nhttps://streamidvo.multitvsolution.in//live//APHDHindi-a//p4.m3u8"
    },
			 {
      "name": "Ten Sports",
      "tvg": {
        "id": "",
        "name": "",
        "language": "",
        "country": "",
        "logo": "https://i.ytimg.com/vi/Jxz6MAa3fFM/maxresdefault.jpg",
        "url": ""
      },
      "group": {
        "title": "DOCUMENTARY"
      },
      "url": "http://125.209.99.45:43568/BRN/TenSports.stream/playlist.m3u8",
      "raw": "#EXTINF:-1 tvg-logo=\"https://www.upcdirectrendeles.eu/upload_files/chanels/aphd.jpg\" group-title=\"DOCUMENTARY\",Animal Planet Hindi\nhttps://streamidvo.multitvsolution.in//live//APHDHindi-a//p4.m3u8"
    },
    
			  {
      "name": "Pitaara",
      "tvg": {
        "id": "",
        "name": "",
        "language": "",
        "country": "",
        "logo": "https://yt3.ggpht.com/a/AATXAJwg_-pBue45FESL3QWingpnS5FCiErYeaUQ3XDSJw=s900-c-k-c0xffffffff-no-rj-mo",
        "url": ""
      },
      "group": {
        "title": "DOCUMENTARY"
      },
      "url": "https://m-c16-j2apps.s.llnwi.net/hls/5366.Pitaara.in_480p/index.m3u8",
      "raw": "#EXTINF:-1 tvg-logo=\"https://www.upcdirectrendeles.eu/upload_files/chanels/aphd.jpg\" group-title=\"DOCUMENTARY\",Animal Planet Hindi\nhttps://streamidvo.multitvsolution.in//live//APHDHindi-a//p4.m3u8"
    }
	
    
			]
		
			



		
		}
	}
	componentDidMount()
	{
		localStorage.clear();
	}
	watch = (channel)=>{
		localStorage.url = channel.url;
		localStorage.logo = channel.tvg.logo;
		localStorage.name = channel.name
		this.props.history.push("/watchtv")
	}
	render()
	{
		
		return <div className="container mt-4 justify-content-center">
				<div className="row">
				{
					this.state.items.map((channel,index)=>{
						return 	<div className="card mt-3 ml-3" style={{width: "8rem",cursor:"pointer"}} key={index} onClick={()=> this.watch(channel)}>
		  				<img  src={channel.tvg.logo} className="card-img-top" alt={channel.name}/>
		  				{/*<div class="card-body" style={{backgroundColor:"#e6ecf5"}}>
    					<p class="card-text" style={{fontSize:"2ch"}}>  {channel.name} </p>
    					</div>*/}
					</div>
					})
				}
				</div>
			</div>
	}
}

export default Livetv