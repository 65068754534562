import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import {Link} from 'react-router-dom'
class Header extends React.Component
{
	render()
	{
		return   <Navbar bg="dark" variant="dark">
    				<Navbar.Brand href="/"><span style={{fontSize:"2ch"}}>PB26-TV</span> <i className="text-danger fas fa-tv"></i>
    				
    			</Navbar.Brand>
	    			<div className="nav d-flex justify-content-end">
				      <li>
				        <Link className=" text-light nav-link" to="/"><i style={{fontSize:"1.5ch"}} className="fas fa-home"/><span className="links"> Home</span></Link>
				      </li>
				      <li>
				        <Link className=" text-light nav-link" to="/livetv"><i style={{fontSize:"1.5ch"}} className="fas fa-tv"/><span className="links"> Live TV</span></Link>
				      </li>
				     
				   
    				</div>
  				</Navbar>
	}
}
export default Header