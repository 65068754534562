import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import {Link} from 'react-router-dom'
class PhotoCrousel extends React.Component
{
	render()
	{
		return (<Carousel controls={true}>
  
  
  
  <Carousel.Item>
  <Link to="/livetv">
    <img
      className="d-block w-100"
      src="2.jpeg"
      alt="Third slide"
    />
        <Carousel.Caption className="text-dark d-none d-sm-inline d-md-inline d-lg-inline">
      <h3  style={{fontSize:"2.5ch"}}>Watch Live TV For Free</h3>
      <span  style={{fontSize:"1.5ch"}}>only at PB26-TV <i className="fas text-danger fa-tv"/></span>
    </Carousel.Caption>
    </Link>
  </Carousel.Item>
</Carousel>)
	}
}

export default PhotoCrousel