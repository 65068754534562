import React,{Component} from 'react'
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton
} from 'video-react';
import DownloadButton from './DownloadButton'
import HLSSource from './HLSSource'

class WatchTV extends Component
{	
	constructor(props)
	{
		super(props)
		this.state = {
			name : '',
			url : '',
			country : '',
			type : ''
		}
	}
	
	componentDidMount()
	{
		if(!localStorage.movieUrl)
		{
			localStorage.clear();
			this.props.history.push("/livetv")
		}
		else{

			this.setState({ name : localStorage.movieName, url : localStorage.movieUrl, logo : localStorage.movieLogo})
		}

		console.log(this.state.url);
	}
	errorHandle = (event)=>{
		console.log("ERROR HANDLE PLAYER")
	}
	render()
	{
		return <div className="container mt-2 mb-5">
				<h3>{this.state.name}</h3><hr/>
			<Player autoPlay={true} src={this.state.url} onError={this.errorHandle}>
				
				<ControlBar>
			        <ReplayControl seconds={10} order={1.1} />
			        <ForwardControl seconds={30} order={1.2} />
			        <CurrentTimeDisplay order={4.1} />
			        <TimeDivider order={4.2} />
			        <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
			        <VolumeMenuButton disabled />
			        <DownloadButton order={7}/>
			      </ControlBar>
			</Player>
			</div>
	}
}

export default WatchTV